import React, { useRef, useEffect, useState } from "react";
import Header from "./components/header";

function SocialPage() {
  
 console.log("SocialPage");
  return (
    <div>
      <Header />
    </div>
  );
}

export default SocialPage;